<template>
  <div class="PlanejamentoAcaoForm_Rodape">
    <v-container fluid>
      <v-row>
        <v-col align="right">
            <v-btn slot="acoes-padrao" @click="cancelar(true)" class="mr-3">
              {{ $t('label.voltar') }}
            </v-btn>
            <v-btn slot="acoes-padrao" @click="validaConcluir()"
                   v-if="this.acaoEdicao !== null && this.acaoEdicao !== undefined"
                   class="mr-3"
                   dark
                   color="red">
              {{ $t('label.concluir') }}
            </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <confirm
      ref="cancelamentoDialog"
      :persistent="true">
    </confirm>
    <confirm
      ref="concluirAcaoSemFluxoDialog"
      :max-width="'500'"
      :message="$t('message.concluir_acao_sem_fluxo_massivo')"
      :persistent="true"
      @disagree="naoConcluirAcao"
      @agree="concluirAcao"/>
  </div>
</template>
<script>
import Confirm from '../../../produto/shared-components/vuetify/dialog/Confirm';

export default {
  props: {
    edicao: Boolean,
    somenteLeitura: Boolean,
    acaoEdicao: Object,

    getComponentesPassos: Function,

    callbackAcao: {
      type: Function,
      default: null,
    },

    validaComponentePasso: Function,
    validaComponentePassoSalvar: Function,

    indTemEvidencia: Boolean,
    usuarioCliente: Boolean,
    usuarioClientePodeAcessarPassoContas: Boolean,
  },
  components: {
    Confirm,
  },
  data() {
    return {
      planejamentoAcaoResource: this.$api.planejamentoAcaoCadastro(this.$resource),
      planejamentoAcaoListagemResource: this.$api.planejamentoAcaoListagem(this.$resource),
      planejamentoAcaoMassivoResource: this.$api.suzanoPlanejamentoAcaoListagemMassiva(this.$resource),
      stepForm: 1,
      avancaPasso: () => {},
      acaoConcluir: {
        type: Function,
        default: () => ({}),
      },
      blockerDuplicacao: false,
    };
  },
  methods: {
    validaConcluir() {
      if (this.validaConcluirAcao()) {
        this.$refs.concluirAcaoSemFluxoDialog.open();
      }
    },
    cancelar(forceVoltaParaLista = false) {
      if (this.somenteLeitura) {
        this.$router.push({
          name: this.$route.params.from || 'planejamento-acao-massivo',
        });
        return;
      }

      this.$refs.cancelamentoDialog
        .openWithParams(
          this.$t('title.atencao'),
          this.$t('message.deseja_cancelar_edicao'),
          (ok) => {
            if (ok) {
              if (forceVoltaParaLista) {
                this.$router.push({
                  name: 'planejamento-acao-massivo',
                });
              } else {
                this.$router.push({
                  name: this.$route.params.from || 'planejamento-acao-massivo',
                });
              }
            }
          },
        );
    },
    salvarRecarregar() {
      this.salvar()
        .then((idAcao) => {
          if (this.callbackAcao) {
            this.callbackAcao(idAcao);
          }
          this.alternaRota(idAcao);
        })
        .catch((err) => {
          if (err) {
            this.$toast(err.data.error);
          }
        });
    },
    salvar(isSolicitandoAprovacao = false) {
      if (!this.validaAntesDeSalvar(isSolicitandoAprovacao)) {
        return Promise.reject();
      }

      const acao = this.formataAcaoParaEnvio();

      if (!this.edicao) {
        return this.salvarNovo(acao);
      }
      return this.atualizar(acao);
    },
    formataAcaoParaEnvio() {
      const componentes = this.getComponentesPassos();
      const acao = {
        id: this.acaoEdicao.id,
      };

      Object.keys(componentes).forEach((k) => {
        const componente = componentes[k];
        if (componente.preencherObjetoFormatado) {
          componente.preencherObjetoFormatado(acao);
        }
      });
      return acao;
    },
    salvarNovo(acao) {
      return this.planejamentoAcaoResource.salvarAcao(acao)
        .then((res) => {
          this.$toast(this.$t('message.adicionado_confira_tabela'));
          const idAcao = res.data;
          return idAcao;
        });
    },
    atualizar(acao) {
      return this.planejamentoAcaoResource.atualizarAcao(acao)
        .then((res) => {
          this.$toast(this.$t('message.acao_atualizada'));
          const idAcao = res.data;
          return idAcao;
        });
    },
    alternaRota() {
      this.$router.push({
        name: this.$route.params.from || 'planejamento-acao-massivo',
      });
    },
    validaConcluirAcao() {
      if (this.blockerDuplicacao) {
        return false;
      }
      this.blockerDuplicacao = true;
      return true;
    },
    naoConcluirAcao() {
      this.blockerDuplicacao = false;
    },
    concluirAcao() {
      this.planejamentoAcaoMassivoResource.concluirAcaoMassiva(this.acaoEdicao.idAcaoPrevia)
        .then(() => {
          this.$toast(this.$t('message.acoes_concluidas'));
          this.alternaRota();
        })
        .catch((err) => {
          if (err.data.error === 'ja_aprovada') {
            this.$toast(this.$t('message.ja_aprovada'));
            return;
          }
          const erros = JSON.parse(err.bodyText);
          const listaErros = erros.erros[0].split('.,');
          listaErros.forEach((e) => {
            this.$toast(e);
          });
        });
    },
  },
  mounted() {
    const { passoFinal } = this.$route.params;
    if (passoFinal === true) {
      this.abreUltimoPasso();
    } else if (this.edicao) {
      this.carregarEdicao();
    }
  },
};
</script>
<style>
  .PlanejamentoAcaoForm_Rodape {
    height: auto !important;
    background-color: #EEEEEE;
    display: flex;
    position: fixed;
    z-index: 4;
    transform: translate(0);
    transition: all .4s cubic-bezier(.25,.8,.5,1);
    bottom: 0px;
    left: 0;
    width: 100%;
  }
  .PlanejamentoAcaoForm_Rodape .v-stepper {
    background: none;
    border: none;
    box-shadow: none;
  }
  .PlanejamentoAcaoForm_Rodape .v-stepper__header,
  .PlanejamentoAcaoForm_Rodape .v-stepper__step {
    height: 56px;
  }
  .PlanejamentoAcaoForm_Rodape__Passo {
    align-items: stretch;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
</style>
