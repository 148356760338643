<template>
  <v-dialog
    v-model="menu"
    :close-on-content-click="false"
    offset-y
    persistent
    bottom>
    <template v-slot:activator="{ on }">
      <v-select
        v-model="item"
        :items="itemsR"
        append-icon
        class="select--sem--linha"
        :label="$tc('label.distribuicao_valor', 2)"
        multiple
        readonly>
        <template v-slot:selection="data">
          <v-icon v-on="on">{{ data.item }}</v-icon>
        </template>
      </v-select>
    </template>

    <template>
      <v-card>
        <v-container fluid grid-list-md>
          <planejamento-acao-form-conta-corrente
            v-if="!!objetoPassoGeral"
            :tipo-acao="tipoAcao"
            :configuracao="configuracao"
            :objeto-passo-geral="objetoPassoGeral"
            :objeto-passo-comportamento="objetoPassoComportamento"
            :acao-edicao="acaoEdicao"
            :nova-acao="novaAcao"
            :somente-leitura="somenteLeitura"
            :edicao="edicao"
            :key="key"
            ref="componenteContaCorrente"
            @ContaCorrenteRedistribuicaoMixin__abrirRedistribuicaoContas="abrirRedistribuicaoContas"
            @PlanejamentoAcaoFormContaCorrente__evento="(e) => $emit('PlanejamentoAcaoForm__evento', e)">
            <div class="card-title" slot="titulo-acao">
              {{ $t('label.criacao_acao')}} :: {{ tipoAcao.nome }} :: {{$tc('title.resumo', 1)}}
            </div>
          </planejamento-acao-form-conta-corrente>
          <v-container fluid>
            <v-row>
              <v-col align="right">
                <v-btn slot="acoes-padrao" @click="cancelar()"
                       class="mr-3">
                  {{ $t('label.cancelar') }}
                </v-btn>
                <v-btn slot="acoes-padrao" @click="cancelar('confirmar')"
                       class="mr-3"
                       dark
                       color="primary">
                  {{ $t('label.confirmar') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-container>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import PlanejamentoAcaoFormContaCorrente from './form/PlanejamentoAcaoFormContaCorrente';

export default {
  name: 'PlanejamentoAcaoMassivaContaCorrente.vue',
  components: {
    PlanejamentoAcaoFormContaCorrente,
  },
  props: {
    configuracao: {
      type: Object,
      required: true,
    },
    novaAcao: Boolean,
    somenteLeitura: Boolean,
    edicao: Boolean,
    objetoPassoComportamento: {
      type: Object,
      default: () => ({}),
    },
    objetoPassoGeral: {
      type: Object,
      required: true,
    },
    acaoEdicao: {
      type: Object,
      default: () => {},
    },
    tipoAcao: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dialog: false,
      item: 'attach_money',
      itemsR: ['attach_money'],
      menu: false,
      key: 0,
    };
  },
  methods: {
    abrirRedistribuicaoContas(avancaPasso) {
      this.avancaPasso = avancaPasso;
      this.$refs.redistribuicaoContasDialog.open();
    },
    cancelar(item) {
      let validacao = false;
      if (item === 'confirmar') {
        validacao = this.$refs.componenteContaCorrente.validaSalvar();
        if (validacao) {
          this.menu = false;
          this.$emit('PlanejamentoAcaoFormCampos__HabilitaSalvar');
        }
      } else {
        this.menu = false;
        this.$refs.componenteContaCorrente = undefined;
        this.$emit('PlanejamentoAcaoFormCampos__ResetaConta');
      }
    },
  },
};
</script>

<style scoped>

</style>
