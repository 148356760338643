<template >
    <v-card v-model="menu">
      <v-card-title>
        {{ $tc('label.intervalo_meta', 1) }}
      </v-card-title>
      <v-container fluid>
        <v-row>
          <v-col cols="12">
            <v-data-table
                :headers="headers()"
                :items="metas"
                hide-default-footer
                disable-pagination
                :items-per-page="-1"
                :no-data-text="$t('message.nenhum_intervalo')"
                style="overflow:hidden">
              <template v-slot:item="{ item }">
                <tr>
                  <td class="text-center">
                    <span v-text="exibirMeta(item.vlrMetaDe)"></span>
                  </td>
                  <td class="text-center">
                    <span v-if="item.vlrMetaAte" v-text="exibirMeta(item.vlrMetaAte)"></span>
                  </td>
                  <td class="text-center">
                    <span v-text="exibirPagamento(item.vlrBonificacao)"></span>
                    <v-tooltip top v-if="destacarMeta">
                      <template v-slot:activator="{ on }">
                        <v-icon dark color="warning" small v-on="on">error_outline</v-icon>
                      </template>
                      <span>{{ $tc('message.alterar_forma_bonificacao_redefinir_meta', 1) }}</span>
                    </v-tooltip>
                  </td>
                  <td class="justify-center px-0" style="text-align: center;" v-if="!somenteLeitura">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon class="mx-0" v-on="on" @click="editar(item)">
                          <v-icon>edit</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('label.editar') }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn icon class="mx-0" v-on="on" @click="openConfirmRemocao(item)">
                          <v-icon>delete</v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $tc('label.remover') }}</span>
                    </v-tooltip>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-btn absolute
                   small
                   right
                   top
                   icon
                   @click="novo"
                   color="primary"
                   v-if="!somenteLeitura">
              <v-tooltip bottom left>
                <span>{{ $t('label.novo') }}</span>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">add</v-icon>
                </template>
              </v-tooltip>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn slot="acoes-padrao" @click="cancelar" class="mr-3">
            {{ $t('label.cancelar') }}
          </v-btn>
          <v-btn slot="acoes-padrao" @click="cancelar"
                 class="mr-3"
                 dark
                 color="primary">
            {{ $t('label.confirmar') }}
          </v-btn>
        </v-card-actions>
      </v-container>

      <v-dialog v-model="showModal">
        <v-form ref="formModal" lazy-validation>
          <v-card>
            <v-card-title>
            <span class="headline">
              {{ $tc('label.intervalo_meta', 1) }}
            </span>
            </v-card-title>
            <v-card-text>
              <v-container fluid grid-list-md>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <input-money
                        v-if="!calculoVolume"
                        :label="`${$tc('label.meta_de', 1)} *`"
                        v-model="meta.vlrMetaDe"
                        :disabled="somenteLeitura"
                        class="v-text-field"
                        :rules="[rules.required]"
                        :required="true"/>
                    <v-text-field
                        v-else
                        :label="`${$tc('label.meta_de', 1)} *`"
                        v-model="meta.vlrMetaDe"
                        :disabled="somenteLeitura"
                        type="Number"
                        min="0"
                        :suffix="sigla"
                        :rules="[rules.required]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <input-money
                        v-if="!calculoVolume"
                        :label="$tc('label.meta_ate', 1)"
                        v-model="meta.vlrMetaAte"
                        :disabled="somenteLeitura"
                        :rules="[rules.metaAteMenor]"
                        class="v-text-field"/>
                    <v-text-field
                        v-else
                        :label="$tc('label.meta_ate', 1)"
                        v-model="meta.vlrMetaAte"
                        :disabled="somenteLeitura"
                        type="Number"
                        min="0"
                        :suffix="sigla"
                        :rules="[rules.metaAteMenorVolume]"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field
                        v-if="verbaPercentual"
                        :label="`${$t('label.verba', 1)} *`"
                        v-model="meta.vlrBonificacao"
                        suffix="%"
                        :disabled="somenteLeitura"
                        type="Number"
                        min="0"
                        :rules="[rules.required]"
                    ></v-text-field>
                    <input-money
                        v-else
                        :label="`${$t('label.verba', 1)} *`"
                        v-model="meta.vlrBonificacao"
                        :disabled="somenteLeitura"
                        class="v-text-field"
                        :rules="[rules.required]"
                        :required="true"/>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn v-if="somenteLeitura" text @click.native="cancela">{{ $t('label.fechar') }}</v-btn>
              <v-btn v-if="!somenteLeitura" text @click.native="cancela">{{ $t('label.cancelar') }}</v-btn>
              <v-btn v-if="!somenteLeitura" color="primary darken-1" text @click.native="salvar">{{ $t('label.salvar') }}</v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <confirm
          ref="confirmRemocao"
          :message="$t('message.deseja_remover_meta')"
          :persistent="true"
          @agree="remover">
      </confirm>
    </v-card>
</template>
<script>
import InputMoney from '../../../produto/shared-components/inputs/InputMoney';
import Confirm from '../../../produto/shared-components/vuetify/dialog/Confirm';
import {
  convertToMoney,
  convertToPercent,
  convertToNumberFormat,
  numberBetweenValues,
} from '../../../produto/common/functions/numeric';

export default {
  data() {
    return {
      menu: true,
      showModal: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        metaAteMenor: () => this.meta.vlrMetaAte > this.meta.vlrMetaDe
            || this.meta.vlrMetaAte === 0 || this.$t('errors.meta_ate_maior_meta_de'),
        metaAteMenorVolume: () => !this.meta.vlrMetaAte
            || parseInt(this.meta.vlrMetaAte, 10) > parseInt(this.meta.vlrMetaDe, 10)
            || this.meta.vlrMetaAte === '0' || this.$t('errors.meta_ate_maior_meta_de'),
      },
      meta: {
        index: -1,
      },
      edicao: false,
    };
  },
  props: {
    somenteLeitura: {
      type: Boolean,
      default: false,
    },
    calculoVolume: {
      type: Boolean,
      default: false,
    },
    destacarMeta: {
      type: Boolean,
      default: false,
    },
    metas: {
      type: Array,
      default: () => ([]),
    },
    sigla: {
      type: String,
      default: '',
    },
    verbaPercentual: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    metas: {
      handler() {
        this.ajustarValorAcao();
      },
      deep: true,
    },
  },
  components: {
    InputMoney,
    Confirm,
  },
  methods: {
    convertToMoney,
    convertToPercent,
    convertToNumberFormat,
    numberBetweenValues,
    cancelar() {
      this.$emit('PlanejamentoAcaoFormApuracaoIntervalo__fecharModal');
    },
    novo() {
      this.retirarDestaque();
      this.edicao = false;
      this.meta = {
        index: -1,
      };
      setTimeout(() => this.$refs.formModal.reset(), 1E2);
      this.showModal = true;
    },
    cancela() {
      this.showModal = false;
    },
    ajustarValorAcao() {
      if (!this.verbaPercentual && this.metas.length > 0) {
        this.$emit('PlanejamentoAcaoFormApuracaoIntervalo__maiorValorMetaAte',
          this.pegarMaiorValorDaMeta());
      }
    },
    pegarMaiorValorDaMeta() {
      return this.metas.map((m) => m.vlrBonificacao)
        .reduce((a, b) => Math.max(a, b));
    },
    salvar() {
      if (this.meta.vlrMetaDe) {
        this.meta.vlrMetaDe = Number(this.meta.vlrMetaDe);
      }
      if (this.meta.vlrMetaAte) {
        this.meta.vlrMetaAte = Number(this.meta.vlrMetaAte);
      }
      if (!this.$refs.formModal.validate() || this.intervaloEmConflito()
          || this.valoresNegativos()) return;
      if (!this.edicao) {
        this.metas.push(this.meta);
      }
      this.showModal = false;
    },
    headers() {
      return [
        {
          text: this.$t('label.meta_de'), value: 'meta_de', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.meta_ate'), value: 'meta_ate', sortable: false, align: 'center',
        },
        {
          text: this.$t('label.verba'), value: 'vlr_bonificacao', sortable: false, align: 'center',
        },
        {
          text: '', value: '', sortable: false, align: 'center',
        },
      ];
    },
    exibirMeta(valor) {
      if (this.calculoVolume) {
        return convertToNumberFormat(valor);
      }
      return convertToMoney(valor);
    },
    exibirPagamento(valor) {
      if (this.verbaPercentual) {
        return convertToPercent(valor);
      }
      return convertToMoney(valor);
    },
    retirarDestaque() {
      if (this.destacarMeta) {
        this.$emit('retirarDestaque');
      }
    },
    editar(item) {
      this.retirarDestaque();
      this.edicao = true;
      this.meta = item;
      this.meta.index = this.metas.indexOf(item);
      this.showModal = true;
    },
    openConfirmRemocao(item) {
      this.retirarDestaque();
      this.meta = item;
      this.$refs.confirmRemocao.open();
    },
    remover() {
      this.metas.splice(this.metas.indexOf(this.meta), 1);
    },
    intervaloEmConflito() {
      for (let i = 0; i < this.metas.length; i += 1) {
        const intervalo = this.metas[i];
        if (this.meta.index !== i
            && this.validarConflito(intervalo.vlrMetaDe, intervalo.vlrMetaAte)) {
          this.$toast(this.$t('errors.meta.intervalos.conflito'));
          return true;
        }
      }
      return false;
    },
    validarConflito(vlrMetaDe, vlrMetaAte) {
      if (!this.meta.vlrMetaAte) {
        return this.validarConflitoEntreValorInfinito(vlrMetaDe, vlrMetaAte);
      }
      return this.validarConflitoEntreValores(vlrMetaDe, vlrMetaAte);
    },
    validarConflitoEntreValores(vlrMetaDe, vlrMetaAte) {
      return this.numberBetweenValues(this.meta.vlrMetaDe, vlrMetaDe, vlrMetaAte)
          || this.numberBetweenValues(this.meta.vlrMetaAte, vlrMetaDe, vlrMetaAte)
          || this.numberBetweenValues(vlrMetaAte, this.meta.vlrMetaDe,
            this.meta.vlrMetaAte)
          || this.numberBetweenValues(vlrMetaDe, this.meta.vlrMetaDe,
            this.meta.vlrMetaAte);
    },
    validarConflitoEntreValorInfinito(vlrMetaDe, vlrMetaAte) {
      return !vlrMetaAte
          || this.numberBetweenValues(this.meta.vlrMetaDe, vlrMetaDe, vlrMetaAte)
          || this.meta.vlrMetaDe < vlrMetaDe;
    },
    valoresNegativos() {
      if (this.meta.vlrMetaDe < 0 || this.meta.vlrMetaAte < 0 || this.meta.vlrBonificacao < 0) {
        this.$toast(this.$t('errors.meta.negativa'));
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (this.metas.length > 0) {
      this.ajustarValorAcao();
    }
  },
};
</script>
